import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  modal: {
    outline: 'none',
    maxWidth: '100%',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    color: theme.palette.common.black,
    ['& .ReactModal__Overlay']: {
      position: 'absolute',
    },
    [`@media (min-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: 600,
    },
  },
  modalContainer: {
    position: 'relative',
    outline: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 10,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 2,
    outline: 'none',
  },
  container: {
    padding: 20,
  },
  title: {
    margin: '20px 0',
    color: theme.palette.common.black,
  },
  loggedOut: {
    margin: '40px 0 20px 0',
    color: theme.palette.common.black,
    fontSize: '2.5rem !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '2rem !important',
    },
  },
  paragraph: {
    margin: '20px 0',
    color: theme.palette.grey[700],
  },
  shareBox: {
    margin: '0',
  },
  formShare: {
    margin: '0 0 20px',
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
    textAlign: 'start',
    margin: '0px 20px',
  },
  joinLinkSpan: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: '5px',
    marginLeft: '5px',
  },
}))
