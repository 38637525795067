import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from './../../assets/images/close.svg'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Typography from '@material-ui/core/Typography'
import CHROutlinedButton from '../outlinedButton'
import { navigate } from 'gatsby'
import { ROUTES } from '../../constants'

interface ChirpyestProRequestsModal {
  handleClose: any
  openStatus: boolean
  error: string
  t: TFunction
}

const ChirpyestProRequestsModal = ({
  handleClose,
  openStatus,
  error,
  t,
}: ChirpyestProRequestsModal) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openStatus}
        onClose={handleClose}
        aria-labelledby="createChirpyestProRequestsMdoal"
        className={classes.modal}
      >
        <section className={classes.modalContainer}>
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>

          <div className={classes.container}>
            {error ? (
              <>
                {error === 'LoggedOut' ? (
                  <Typography
                    variant="h2"
                    component="h2"
                    className={classes.loggedOut}
                  >
                    please{' '}
                    <span
                      className={classes.joinLinkSpan}
                      onClick={() => navigate(ROUTES.join)}
                    >
                      join
                    </span>{' '}
                    or{' '}
                    <span
                      className={classes.joinLinkSpan}
                      onClick={() => navigate(ROUTES.signIn)}
                    >
                      sign in
                    </span>{' '}
                    to proceed with this request
                  </Typography>
                ) : (
                  <Typography
                    variant="h2"
                    component="h2"
                    className={classes.title}
                  >
                    {error}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography
                  variant="h2"
                  component="h2"
                  className={classes.title}
                >
                  {t('chirpyestPro.chirpyestProRequestsModalTitle')}
                </Typography>
                <div className={classes.shareBox}>
                  <div className={classes.formShare}>
                    <div className={classes.inputContainer}>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.paragraph}
                      >
                        {t('chirpyestPro.chirpyestProRequestsMidalParagraph')}
                      </Typography>
                      <CHROutlinedButton
                        label={t(
                          'chirpyestPro.chirpyestProRequestsMidalStartShoppingBtn'
                        )}
                        onClick={() => {
                          navigate('/shop')
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </Dialog>
    </>
  )
}
export default withTranslation()(ChirpyestProRequestsModal)
