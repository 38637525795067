import { makeStyles } from '@material-ui/core/styles'
import { FONTS, COLORS } from '../constants'

export const styles = makeStyles(theme => ({
  headingTextContainer: {
    width: '90%',
    margin: 'auto',
    maxWidth: 1650,
    padding: '37px 20px 0 20px',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      margin: 0,
      paddingTop: 10,
      width: '100%',
    },
  },
  headingTitle: {
    fontSize: '5rem',
    marginBottom: 10,
    letterSpacing: 'normal',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '2.8125rem',
    },
  },
  headingDescription: {
    fontSize: '1rem !important',
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    maxWidth: 515,
    letterSpacing: 'normal !important',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      maxWidth: 333,
      marginBottom: 20,
    },
  },
  becomeProTitle: {
    width: '90%',
    margin: 'auto',
    maxWidth: 1650,
    padding: '10px 20px 60px 20px',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      margin: 0,
      paddingTop: 0,
      paddingBottom: 40,
      width: '100%',
      fontSize: '2.25rem',
    },
  },
  whyProTitle: {
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '2.25rem',
    },
  },
  whyProDescription: {
    fontSize: '1rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    marginBottom: 60,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      marginBottom: 40,
    },
  },
  goPrpContainer: {
    padding: '136px 20px 20px 20px',
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      textAlign: 'center',
    },
  },
  firstStep: {
    maxWidth: 700,
    margin: 'auto',
    marginBottom: 40,
    textAlign: 'center',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      textAlign: 'left',
      fontSize: '2.25rem',
    },
  },
  goProCaption: {
    fontFamily: FONTS.Graphik.GraphikLight,
    marginTop: 40,
    color: COLORS.lightGrey,
  },
  customWidth: {
    maxWidth: 500,
  },
  customWidth1: {
    maxWidth: 444,
  },
  customWidth2: {
    maxWidth: 370,
  },
}))
