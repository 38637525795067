import * as React from 'react'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import CHRArrowButton from './../arrowButton'
import placeholderImage from './../../assets/images/case-studies.jpg'
import { navigate } from 'gatsby'
import { ROUTES } from '../../constants'
import Img from 'gatsby-image'

const getStyle = (isMediaContained: any) => ({
  maxWidth: '100%',
  objectFit: isMediaContained ? 'contain' : 'cover',
})

interface FloatImageRightProps {
  leftChildren: React.ReactNode
  t: TFunction
  buttonLabel: string
  handleAddChirpyestToBrowserClick?: Function
  image: any
  video: any
  withoutButton: boolean
  withBackground?: boolean
  mediaHasBg: boolean
  isMediaContained: boolean
  isMediaFloatedTop: boolean
  isGatsbyImg: boolean
}

const CHRFloatImageRight = ({
  leftChildren,
  buttonLabel,
  t,
  handleAddChirpyestToBrowserClick,
  image,
  video,
  withoutButton,
  withBackground,
  mediaHasBg = false,
  isMediaContained,
  isMediaFloatedTop,
  isGatsbyImg,
}: FloatImageRightProps) => {
  const classes = styles()

  const isBgColored: string = withBackground && classes.background
  const mediaStyle: string = isMediaContained && classes.mediaIsContained
  const floatMediaTop: string = isMediaFloatedTop && classes.floatMediaTop

  const renderImg = (image: any, isGatsbyImg: boolean) => {
    if (!image) {
      return null
    }

    if (isGatsbyImg) {
      return (
        <Img
          fluid={image}
          alt={t('imageAlts.placeholder')}
          style={getStyle(isMediaContained)}
          imgStyle={getStyle(isMediaContained)}
          className={`${classes.mediaColumnImage} ${mediaStyle}`}
        />
      )
    }
    return (
      <img
        src={image ? image : placeholderImage}
        alt={t('imageAlts.placeholder')}
        className={`${classes.mediaColumnImage} ${mediaStyle}`}
      />
    )
  }

  return (
    <div className={`${classes.leftFloat} ${isBgColored}`}>
      <article className={classes.textColumn}>
        <div className={classes.textContent}>
          {leftChildren}
          <div className={`${classes.flexEnd} ${classes.desktop}`}>
            {!withoutButton && (
              <CHRArrowButton
                label={buttonLabel ? buttonLabel : t('shared.addChirpyest')}
                onClick={handleAddChirpyestToBrowserClick}
              />
            )}
          </div>
          <div className={`${classes.flexEnd} ${classes.mobile}`}>
            {!withoutButton && (
              <CHRArrowButton
                label={buttonLabel ? buttonLabel : t('shared.joinChirpyest')}
                onClick={() => navigate(ROUTES.join)}
              />
            )}
          </div>
        </div>
      </article>
      <div className={`${classes.mediaColumn} ${floatMediaTop}`}>
        <div className={classes.mediaContainer}>
          {renderImg(image, isGatsbyImg)}
          {video && (
            <video
              playsInline
              loop
              autoPlay
              muted
              preload="none"
              className={classes.mediaColumnImage}
            >
              <source src={video.webM} type="video/webm" />
              <source src={video.mp4} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(CHRFloatImageRight)
