import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS } from '../../constants'

export const styles = makeStyles(theme => ({
  leftFloat: {
    display: 'flex',
    flexDirection: 'column',
  },
  mediaColumnImage: {
    width: '100%',
  },
  mediaIsContained: {
    objectFit: 'contain',
  },
  pinkMediaColumn: {
    backgroundColor: COLORS.watermelonPink,
  },
  blackBox: {
    display: 'block',
    bottom: '15%',
    left: '67%',
    width: '30%',
    height: 0,
    paddingBottom: '40%',
    position: 'absolute',
  },
  blackBoxImage: {
    width: '100%',
  },
  mediaContainer: {
    width: '100%',
  },
  textContent: {
    width: '90%',
    margin: '0 auto',
    padding: '5% 0',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
  },
  background: {
    background: COLORS.whiteStain,
  },
  mobileLandingNoBg: {
    backgroundColor: COLORS.white,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      backgroundColor: COLORS.whiteStain,
    },
  },
  mobileLandingWithBg: {
    backgroundColor: COLORS.whiteStain,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      backgroundColor: COLORS.white,
    },
  },

  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    leftFloat: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textColumn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'calc(598 / 1660 * 100%)',
      paddingBottom: 0,
    },
    mediaColumn: {
      width: 'calc(1062 / 1660 * 100%)',
    },
    blackBox: {
      left: 'auto',
      right: '-10%',
      width: '26.25%',
      bottom: '30%',
      height: 0,
      position: 'absolute',
      paddingBottom: '26.25%',
    },
    blackBoxImage: {
      maxWidth: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    howItWorksTextContent: {
      margin: 0,
    },
    flexEnd: {
      marginTop: 30,
    },
  },
  desktop: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
  },
  mobile: {
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
  },
}))
