import * as React from 'react'
import { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import CHRSectionContainer from './../components/sectionContainer'
import { Typography } from '@material-ui/core'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/chirpyestProStyles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import SEO from '../components/seo'
import CHRFloatImageRight from '../components/floatImagRight'
import CHRFloatImageLeft from '../components/floatImageLeft'
import Spinner from '../components/spinner'
import { FONTS, ENDPOINTS } from '../constants'
import CHROutlinedButton from './../components/outlinedButton'
import { useAppState } from '../appState'
import ChirpyestProRequestsModal from '../components/chirpyestProRequestsModal'
import axios from '../axios'
import NotFound from './404'

interface ChirpyestProPageProps {
  t: TFunction
  data: any
}

const ChirpyestProPage = ({ t, data }: ChirpyestProPageProps) => {
  const classes = styles()
  const [appState] = useAppState()
  const [isProRequestModal, setIsProRequestModal] = useState(false)
  const [cmsContent, setCMSContent] = useState(null)
  const [error, setError] = useState('')
  const [proError, setProError] = useState('')

  const handleCreateProRequsetsClick = async () => {
    try {
      setIsProRequestModal(true)
      if (appState?.userId) {
        const url = ENDPOINTS.isRequestingPro.replace(
          ':id',
          `${appState.userId}`
        )
        await axios.post(url)
      } else {
        setProError('LoggedOut')
      }
    } catch (err) {
      setProError(t('messages.somethingWentWrong'))
    }
  }

  const closeChirpyestProRequestsModal = () => {
    setIsProRequestModal(false)
  }

  useEffect(() => {
    const getContent = async () => {
      const data = await axios.get(ENDPOINTS.cmsPro)
      const content = data.data.data.reduce((obj: any, row: any) => {
        obj[row.name] = row.content
        return obj
      }, {})
      setCMSContent(content)
    }

    getContent().catch(() => setError(t('messages.somethingWentWrong')))
  }, [])

  if (error) return <NotFound error={error} />

  if (cmsContent === null) return <Spinner />

  return (
    <Layout>
      <SEO
        title={t('chirpyestPro.title')}
        description={t('company.metaDescription')}
      />
      <div className={classes.headingTextContainer}>
        <Typography
          variant="h4"
          component="h1"
          className={classes.headingTitle}
        >
          {t('chirpyestPro.title')}
        </Typography>
        <Typography variant="h2" className={classes.headingDescription}>
          {t('chirpyestPro.weWantToCollaborate')}
        </Typography>
      </div>
      <div>
        <Typography className={classes.becomeProTitle} variant="h2">
          {t('chirpyestPro.howDoIQualify')}
        </Typography>
        <section>
          <CHRFloatImageLeft
            isMediaFloatedTop
            rightChildren={
              <div>
                <Typography
                  style={{ marginBottom: 10 }}
                  variant="h2"
                  dangerouslySetInnerHTML={{
                    __html: cmsContent['pro page section 1 title'],
                  }}
                ></Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  style={{
                    letterSpacing: 'normal',
                  }}
                  className={classes.customWidth1}
                  dangerouslySetInnerHTML={{
                    __html: cmsContent['pro page section 1 content'],
                  }}
                ></Typography>
              </div>
            }
            image={data.allImageSharp.edges[6].node.fluid}
            isGatsbyImg
            withoutButton
            withBackground
          />
        </section>
        <CHRSectionContainer>
          <section>
            <CHRFloatImageRight
              leftChildren={
                <div>
                  <Typography
                    style={{ marginBottom: 10 }}
                    variant="h2"
                    className={classes.customWidth2}
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 2 title'],
                    }}
                  ></Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.customWidth2}
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 2 content'],
                    }}
                  ></Typography>
                </div>
              }
              image={data.allImageSharp.edges[8].node.fluid}
              isGatsbyImg
              withoutButton
              withBackground
            />
          </section>
        </CHRSectionContainer>
        <CHRSectionContainer>
          <section>
            <CHRFloatImageLeft
              rightChildren={
                <div>
                  <Typography
                    style={{ marginBottom: 10 }}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 3 title'],
                    }}
                  ></Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.customWidth1}
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 3 content'],
                    }}
                  ></Typography>
                </div>
              }
              image={data.allImageSharp.edges[5].node.fluid}
              isGatsbyImg
              withoutButton
              withBackground
            />
          </section>
        </CHRSectionContainer>
      </div>

      <div>
        <div className={classes.headingTextContainer}>
          <Typography
            variant="h4"
            component="h2"
            className={classes.whyProTitle}
            style={{
              marginBottom: 20,
              paddingTop: 83,
            }}
          >
            {t('chirpyestPro.whyGoChirpyestPro')}
          </Typography>
          <Typography variant="h2" className={classes.whyProDescription}>
            {t('chirpyestPro.whatYouUnlock')}
          </Typography>
        </div>
        <section>
          <CHRFloatImageLeft
            rightChildren={
              <div>
                <Typography
                  style={{ marginBottom: 10 }}
                  variant="h2"
                  dangerouslySetInnerHTML={{
                    __html: cmsContent['pro page section 4 title'],
                  }}
                ></Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.customWidth1}
                  dangerouslySetInnerHTML={{
                    __html: cmsContent['pro page section 4 content'],
                  }}
                ></Typography>
              </div>
            }
            image={data.allImageSharp.edges[0].node.fluid}
            isGatsbyImg
            withoutButton
            withBackground
          />
        </section>
        <CHRSectionContainer>
          <section>
            <CHRFloatImageRight
              leftChildren={
                <div>
                  <Typography
                    style={{ marginBottom: 10 }}
                    variant="h2"
                    className={classes.customWidth1}
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 5 title'],
                    }}
                  ></Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.customWidth1}
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 5 content'],
                    }}
                  ></Typography>
                </div>
              }
              image={[
                data.allImageSharp.edges[1].node.fluid,
                {
                  ...data.allImageSharp.edges[2].node.fluid,
                  media: `(min-width: 1024px)`,
                },
              ]}
              isGatsbyImg
              withoutButton
              withBackground
            />
          </section>
        </CHRSectionContainer>
        <CHRSectionContainer>
          <section>
            <CHRFloatImageLeft
              rightChildren={
                <div>
                  <Typography
                    style={{ marginBottom: 10 }}
                    variant="h2"
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 6 title'],
                    }}
                  ></Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.customWidth}
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 6 content'],
                    }}
                  ></Typography>
                </div>
              }
              image={data.allImageSharp.edges[7].node.fluid}
              isGatsbyImg
              withoutButton
              withBackground
            />
          </section>
        </CHRSectionContainer>
        <CHRSectionContainer>
          <section>
            <CHRFloatImageRight
              leftChildren={
                <div>
                  <Typography
                    style={{ marginBottom: 10 }}
                    variant="h2"
                    className={classes.customWidth1}
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 7 title'],
                    }}
                  ></Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.customWidth1}
                    dangerouslySetInnerHTML={{
                      __html: cmsContent['pro page section 7 content'],
                    }}
                  ></Typography>
                </div>
              }
              image={[
                data.allImageSharp.edges[3].node.fluid,
                {
                  ...data.allImageSharp.edges[4].node.fluid,
                  media: `(min-width: 1024px)`,
                },
              ]}
              isGatsbyImg
              withoutButton
              withBackground
            />
          </section>
        </CHRSectionContainer>
        <div className={classes.goPrpContainer}>
          <Typography variant="h4" component="h2" className={classes.firstStep}>
            {t('chirpyestPro.firstStep')}
          </Typography>
          <CHROutlinedButton
            label={t('chirpyestPro.iWantToGoChirpyestPro')}
            font={FONTS.Graphik.GraphikMedium}
            onClick={handleCreateProRequsetsClick}
          />
          <Typography
            variant="caption"
            component="p"
            className={classes.goProCaption}
          >
            {t('chirpyestPro.chirpyestProProgramFeatures')}
          </Typography>
        </div>
        <ChirpyestProRequestsModal
          openStatus={isProRequestModal}
          handleClose={closeChirpyestProRequestsModal}
          error={proError}
        />
      </div>
    </Layout>
  )
}

export default withTranslation()(ChirpyestProPage)

export const query = graphql`
  query {
    allImageSharp(
      filter: {
        fluid: {
          originalName: {
            in: [
              "amazon-and-other-retailers.png"
              "enhanced-shopping-board.png"
              "enhanced-shopping-board-mobile.png"
              "extra-$$$.png"
              "extra-$$$-mobile.png"
              "get-in-early.png"
              "invite-friends.png"
              "pro-tips.png"
              "shop-share-earn-cash-back.png"
            ]
          }
        }
      }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024, quality: 100) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
