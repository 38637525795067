import { makeStyles } from '@material-ui/core/styles'
import { COLORS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  leftFloat: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  mediaColumnImage: {
    width: '100%',
  },
  mediaIsContained: {
    objectFit: 'contain',
  },
  mediaContainer: {
    width: '100%',
  },
  textContent: {
    width: '90%',
    margin: '0 auto',
    padding: '5% 0',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
  },
  floatMediaTop: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  textColumn: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'calc(598 / 1660 * 100%)',
      paddingBottom: 0,
    },
  },

  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    leftFloat: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    mediaColumn: {
      width: 'calc(1062 / 1660 * 100%)',
    },
    textContent: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    howItWorksTextContent: {
      marginLeft: '10%',
    },
    flexEnd: {
      justifyContent: 'flex-start',
      marginTop: 30,
    },
  },
  background: {
    background: COLORS.whiteStain,
  },
  desktop: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
  },
  mobile: {
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
  },
}))
